const convertHiraganaToKatakana = (s: string): string => {
  return s.replace(/[\u3041-\u3096]/g, function (match) {
    return String.fromCharCode(match.charCodeAt(0) + 0x60);
  });
};

export const normalize = (s: string): string => {
  return convertHiraganaToKatakana(s.normalize('NFKC').toLowerCase());
};

export const isFuzzyMatch = (query: string, target: string): boolean => {
  return normalize(target).includes(normalize(query));
};

const capitalizeWord = (word: string): string => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const toCamelCase = (input: string): string => {
  return input
    .split(' ')
    .map((word) => capitalizeWord(word))
    .join(' ');
};

export const trimAndJoinWithDelimiter = (arr: string[], delimiter = ','): string =>
  arr.map((str) => str.trim()).join(delimiter);
